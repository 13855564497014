.Tier-page {
    .wrapper {
      height: 100%;
      max-width: 1160px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      h2 {
        color: #28353e;
        font-size: 20px;
        font-weight: 600;
      }
      .Tier-head {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 15px 10px;
        a {
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0.15px;
          color: #ef981d;
        }
        .Tier-head-right {
          border: 1px solid #ef981d;
          border-radius: 4px;
          width: 200px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
      .Tiers {
        margin: 15px 0px;
        .head{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .download{
            border: 1px solid #ef981d;
            border-radius: 4px;
            width: 150px;
            padding: 10px;
            color: #ef981d;
            margin: 0;
            font-size: 15px;
            &:hover{
              background: #ef981d;
              color: #ffffff;
            }
          }
        }
        .table-wrapper {
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 5px 15px #0000000a;
          border-radius: 4px;
          padding: 15px 15px;
          margin: 10px 0px;
        }
        table {
          width: 100% !important;
          img {
            width: 18px;
            height: 18px;
          }
          .align-left {
            text-align: left;
          }
          .align-right {
            text-align: right;
          }
          .comments{
            max-width: 200px;
          }
          .TierId a {
            text-decoration: underline;
            font-size: 14px;
            color: #ef981d;
          }
          thead {
            background: #fafafa 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: #394956;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            th:first-child {
              border-radius: 4px 0 0 4px;
            }
  
            th:last-child {
              border-radius: 0 4px 4px 0;
            }
          }
          th,
          td {
            text-align: center;
            padding: 8px;
            color: #394956;
            font-size: 14px;
          }
          .Amount {
            font-weight: 500;
          }
          .Amount p {
            font-size: 12px;
          }
          tr:nth-child(even) {
            background: #fafafa;
          }
        }
        .hash {
          color: #ef981d;
          text-decoration: underline;
          cursor: pointer;
        }
        .approved {
          color: #17c969;
        }
        .pending {
          color: #d5a917;
        }
        .rejected {
          color: #c91717;
        }
        .reversal {
          color: #394956;
        }
      }
      .align-pagination{
        display: flex;
        justify-content: center;
      }
      .create-Tier-wrapper {
        height: 100%;
        max-width: 1160px;
        /* margin: 0 auto; */
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        .Tiers {
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 5px 15px #0000000a;
          border-radius: 4px;
          min-height: calc(100vh - 100px);
          padding: 15px 30px;
          h2 {
            color: #28353e;
            font-size: 20px;
            font-weight: 600;
          }
          .field-block {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: 10px 0px;
            .field-wrapper {
              width: 48%;
              label {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0px;
                color: #394956;
                text-transform: uppercase;
              }
              input {
                color: #607b90;
                font-size: 14px;
              }
            }
          }
          .name-block {
            display: flex;
            select {
              width: max-content;
              margin-right: 10px;
            }
            .name-width {
              width: 100%;
            }
          }
          .field-block1 {
            width: 60%;
          }
          .form-group {
            width: 48%;
          }
          .form-button {
            display: flex;
            width: 60%;
            margin-top: 15px;
            button {
              border-radius: 4px;
              letter-spacing: 0.15px;
              font-size: 15px;
              border: none;
              padding: 10px 10px;
              width: 200px;
              height: auto;
              cursor: pointer;
              margin-right: 15px;
            }
            .btn-create {
              background: linear-gradient(180deg, #ef981d 26.56%, #f9bf50) !important;
              color: #ffffff;
            }
            .btn-create:hover {
              background: linear-gradient(180deg, #ef981d 26.56%, #f9bf50) !important;
              color: #ffffff;
            }
            .Transaction-cancel {
              background: #dadce0;
              color: #6e6f76;
              border-radius: 4px;
              letter-spacing: 0.15px;
              font-size: 15px;
              border: none;
              padding: 10px 50px;
              width: 200px;
              height: auto;
              cursor: pointer;
              text-align: center;
            }
            .Transaction-cancel:hover {
              background: #ef981d;
              color: #ffffff;
            }
          }
        }
        /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input:disabled{
      background: #EFEFEF 0% 0% no-repeat padding-box;
    }
      }
      .Tier-profile-head {
        display: flex;
        justify-content: space-between;
        padding: 0px 15px;
        .btn{padding: 0;
          border: 1px solid #E0E0E0;
          border-radius: 4px;
          background: #FFFFFF;
          color: #ef981d;}
        .btn-lg{padding: 0;}
        .split-padding{padding: 10px 15px;}
        .editTier-btn{
          color: #ef981d;
        }
        .dropdown-menu {
          .toggle-item {
            padding: 10px 15px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: #293643;
            font-size: 13px;
            cursor: pointer;
            &:hover {
              background: #e6e6e6 0% 0% no-repeat padding-box;
            }
          }
        }
      }
      .Tier-profile-details {
        background: #fafafa 0% 0% no-repeat padding-box;
        border-radius: 4px;
        display: flex;
        .field-details {
          display: flex;
          margin: 5px 0px;
          color: #394956;
          font-size: 14px;
          label {
            width: 200px;
            font-size: 14px;
            font-weight: 600;
            color: #394956;
            text-transform: uppercase;
          }
        }
        .Tier-profile-details-left {
          padding: 10px 15px;
          width: 50%;
        }
        .Tier-profile-details-right {
          padding: 10px 15px;
          width: 50%;
        }
      }
      .popup {
        .profilePopup-header {
          color: #28353e;
          font-size: 20px;
          font-weight: 600;
          border-bottom: 1px solid #607b90;
          padding: 10px 15px;
          .close {
            color: #28353e;
            opacity: 1;
            font-size: 30px;
          }
        }
        .profilePopup-body {
          padding: 10px 15px;
          label {
            color: #394956;
            text-transform: uppercase;
            font-size: 13px;
          }
          input {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #dadce0;
            border-radius: 4px;
            color: #607b90;
          }
        }
        .profilePopup-footer {
          text-align: center;
          padding: 10px 15px;
          .add-btn {
            background: #ef981d 0% 0% no-repeat padding-box;
            border-radius: 4px;
            padding: 10px 10px;
            color: #ffffff;
            width: 150px;
            outline: none;
            border: none;
            font-size: 15px;
            &:hover {
              background: #ef981d 0% 0% no-repeat padding-box;
            }
          }
        }
      }
    }
    
  }
  
  @media screen and (max-width: 960px) {
    .Tier-page {
      .create-Tier-wrapper {
        .Tiers {
          .field-block {
            width: 100%;
          }
          .field-block1 {
            width: 100%;
          }
          .form-button {
            width: 100%;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .Tier-page {
      .create-Tier-wrapper {
        .Tiers {
          .field-block {
            flex-direction: column;
            .field-wrapper {
              margin: 5px 0px;
              width: 100%;
            }
          }
          .field-block1 {
            flex-direction: column;
            .field-wrapper {
              margin: 5px 0px;
              width: 100%;
            }
          }
          .form-button {
            flex-direction: column;
            .btn-create {
              width: 100%;
              margin-bottom: 10px;
            }
            .Transaction-cancel {
              width: 100%;
            }
          }
        }
      }
      .Tier-profile-details {
        flex-direction: column;
        .field-details {
          display: flex;
          justify-content: flex-start;
          text-align: left;
          label {
            width: 50%;
          }
        }
        .Tier-profile-details-left {
          width: 100%;
        }
        .Tier-profile-details-right {
          width: 100%;
        }
      }
    }
  }
  